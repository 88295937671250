import React, { useState } from 'react';
import { IconButton, Box, Collapse, Table, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDate } from 'helpers/date';
import { DraggableDialogProps } from 'helpers/types';
import DetailRow from '../DetailRow';

const COLLAPSE_TIMEOUT = 'auto';
const ICON_BUTTON_SIZE = 'small';
const TABLE_SIZE = 'small';
const UNAVAILABLE_PRODUCT_NAME = 'Indisponível';

function Row({ item }: { item: NonNullable<DraggableDialogProps['data']>[0] }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const renderDetailRows = () => (
    <>
      <DetailRow
        label1='Nome do Agente de Frete'
        value1={item.ag_name}
        label2='Local de Origem'
        value2={item.loc_org}
        isOpen={open}
      />
      <DetailRow label1='Caminhão' value1={item.truck} label2='Motorista' value2={item.driver} isOpen={open} />
      <DetailRow
        label1='Abastecimento'
        value1={item.supply}
        label2='Código do Agente de Frete'
        value2={item.ag_freight}
        isOpen={open}
      />
      <DetailRow
        label1='GV'
        value1={item.gv}
        label2='Data de Início'
        value2={formatDate(item.date_ini)}
        isOpen={open}
      />
      <DetailRow
        label1='Data de Término'
        value1={formatDate(item.date_end)}
        label2='Código do Produto'
        value2={item.product}
        isOpen={open}
      />
      <DetailRow
        label1='Nome do produto'
        value1={UNAVAILABLE_PRODUCT_NAME}
        label2='Nota Fiscal'
        value2={item.invoice}
        isOpen={open}
      />
    </>
  );

  return (
    <>
      <TableRow className={open ? 'highlighted-row' : ''}>
        <TableCell>
          <IconButton aria-label='expand row' size={ICON_BUTTON_SIZE} onClick={toggleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{item?.transport}</TableCell>
        <TableCell>{item.cart}</TableCell>
        <TableCell>{item.loc_org_name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout={COLLAPSE_TIMEOUT} unmountOnExit>
            <Box margin={1}>
              <Table size={TABLE_SIZE} aria-label='details'>
                {renderDetailRows()}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Row;
