import React from 'react';
import {
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import InfoIcon from '@mui/icons-material/Info';
import { DraggableDialogProps } from 'helpers/types';

import useStyle, { Dialog } from './styles';
import Row from './row';

function DraggableDialog({ open, data, onClose }: DraggableDialogProps) {
  const classes = useStyle();

  return (
    <Draggable handle='.MuiDialogTitle-root'>
      <Dialog open={open} className={classes.dialog}>
        <DialogTitle>
          Documento de transporte
          <Tooltip title='Clique e arraste para mover este modal para a posição desejada'>
            <IconButton aria-label='info' size='small'>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={onClose}
            aria-label='close'
            sx={{
              position: 'absolute',
              right: 8,
              top: 7
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <div className={classes.datatableWrapper}>
              <Table aria-label='collapsible table' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Transporte</TableCell>
                    <TableCell>Carreta</TableCell>
                    <TableCell>Origem</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{data && data.map(item => <Row key={item.id} item={item} />)}</TableBody>
              </Table>
            </div>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </Draggable>
  );
}

export default DraggableDialog;
