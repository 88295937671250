import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { pdfjs } from 'react-pdf';
import { PdfThumbnailProps, RenderPdfThumbnailParams } from 'helpers/types';
import { theme } from 'components/Layout/ThemeProvider';

import useStyles from '../styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const renderPdfThumbnail = async ({ url, width, height, zoom = 4 }: RenderPdfThumbnailParams): Promise<string> => {
  try {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      throw new Error('Contexto do canvas não disponível');
    }

    const pdf = await pdfjs.getDocument(url).promise;
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    const scale = zoom * Math.min(width / viewport.width, height / viewport.height);
    canvas.width = width;
    canvas.height = height;

    const renderContext = {
      canvasContext: context,
      viewport: page.getViewport({ scale })
    };

    await page.render(renderContext).promise;

    return canvas.toDataURL();
  } catch (error) {
    console.error('Erro ao renderizar o PDF:', error);
    throw error;
  }
};

function PdfThumbnail({ file, preview, onLoad }: PdfThumbnailProps) {
  const [thumbnail, setThumbnail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const classes = useStyles(theme);

  useEffect(() => {
    if (file.type === 'application/pdf') {
      setIsLoading(true);
      setError(null);

      renderPdfThumbnail({ url: preview, width: 60, height: 60, zoom: 5 })
        .then(dataUrl => {
          setThumbnail(dataUrl);
          setIsLoading(false);
          onLoad();
        })
        .catch(error => {
          setError('Erro ao carregar o PDF');
          setIsLoading(false);

          console.error('Error generating thumbnail:', error);
        });
    }
  }, [file, preview, onLoad]);

  const renderContent = () => {
    if (isLoading) {
      return <CircularProgress size={20} />;
    }
    if (thumbnail) {
      return <img src={thumbnail} alt={`Preview of ${file.name}`} className={classes.pdfThumbnailImage} />;
    }
    return <p>{error || 'Erro ao carregar'}</p>;
  };

  return <div className={classes.pdfThumbnailContainer}>{renderContent()}</div>;
}

export default PdfThumbnail;
