import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BalanceIcon from '@mui/icons-material/Balance';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { PoliceIcon } from 'components/Shared/SvgIcons';
import { ROLE, User } from 'services/auth';

import {
  CustomList,
  CustomListItem,
  ListIconStyle,
  IconStyle,
  CustomListItemText,
  TextDecorationNone,
  CustomBox,
  CustomIconButton
} from './styles';

const expandedDrawerWidth = 215;
const collapsedDrawerWidth = 60;

function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleDrawer = () => {
    setIsExpanded(!isExpanded);
  };

  const userSerialized = localStorage.getItem('user') || '';
  const currentUser: User = userSerialized ? JSON.parse(userSerialized) : {};
  const userRole = userSerialized ? currentUser.roles[0].name : '';

  const logisticsAndFinance = [
    {
      id: 3,
      name: 'Logística',
      icon: <LocalShippingIcon style={IconStyle} />,
      url: '/dashboard/logistica'
    },
    {
      id: 4,
      name: 'Financeiro',
      icon: <MonetizationOnIcon style={IconStyle} />,
      url: '/dashboard/financeiro'
    }
  ];

  const legalAndAdmin = [
    {
      id: 5,
      name: 'Jurídico',
      icon: <BalanceIcon style={IconStyle} />,
      url: '/dashboard/juridico'
    },
    {
      id: 2,
      name: 'Multas',
      icon: <PoliceIcon style={IconStyle} />,
      url: '/multas'
    }
  ];

  if (userRole === ROLE.ADMIN) {
    legalAndAdmin.push({
      id: 6,
      name: 'Novo Usuário',
      icon: <GroupAddIcon style={IconStyle} />,
      url: '/usuarios/novo'
    });
  }

  return (
    <>
      <CustomIconButton
        onClick={toggleDrawer}
        style={{
          left: isExpanded ? expandedDrawerWidth - 22 : collapsedDrawerWidth - 22
        }}
      >
        {isExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </CustomIconButton>
      <Drawer
        variant='permanent'
        sx={{
          width: isExpanded ? expandedDrawerWidth : collapsedDrawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isExpanded ? expandedDrawerWidth : collapsedDrawerWidth,
            boxSizing: 'border-box',
            background: '#696391',
            border: '1px solid rgb(161 161 161 / 30%)',
            borderRadius: 0,
            transition: 'width 0.3s'
          }
        }}
      >
        <Toolbar style={{ minHeight: 60, display: 'flex', justifyContent: 'flex-end' }} />
        <CustomBox>
          {logisticsAndFinance.length > 0 && (
            <CustomList>
              {logisticsAndFinance.map(item => (
                <Link key={item.id} to={item.url} style={TextDecorationNone}>
                  <Tooltip title={item.name} placement='right'>
                    <CustomListItem>
                      <ListItemIcon style={ListIconStyle}>{item.icon}</ListItemIcon>
                      {isExpanded && <CustomListItemText primary={item.name} />}
                    </CustomListItem>
                  </Tooltip>
                </Link>
              ))}
            </CustomList>
          )}
          {legalAndAdmin.length > 0 && (
            <CustomList style={{ position: 'relative', bottom: 30 }}>
              {legalAndAdmin.map(item => (
                <Link key={item.id} to={item.url} style={TextDecorationNone}>
                  <Tooltip title={item.name} placement='right'>
                    <CustomListItem>
                      <ListItemIcon style={ListIconStyle}>{item.icon}</ListItemIcon>
                      {isExpanded && <CustomListItemText primary={item.name} />}
                    </CustomListItem>
                  </Tooltip>
                </Link>
              ))}
            </CustomList>
          )}
        </CustomBox>
      </Drawer>
    </>
  );
}

export default Sidebar;
