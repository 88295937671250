import LoadingButton from '@mui/lab/LoadingButton';
import { styled, Theme } from '@mui/material/styles';
import { EFontFamily } from 'helpers/constants';
import { rem } from 'helpers/utils';
import { createUseStyles } from 'react-jss';

export const CustomLoadingButton = styled(LoadingButton)(() => ({
  borderRadius: 40,
  fontSize: 13
}));

const useStyle = (theme: Theme) => {
  return createUseStyles({
    container: {
      paddingTop: 15
    },
    dropzone: {
      border: '2px dashed #cccccc',
      borderRadius: 5,
      padding: 20,
      textAlign: 'center',
      cursor: 'pointer',
      transition: 'border-color 0.3s ease',
      position: 'relative',
      '&:hover': {
        borderColor: '#a7a7a7',
        backgroundColor: '#f9f9f9'
      }
    },
    dropzoneIcon: {
      color: '#d3d3d3'
    },
    dropzoneText: {
      marginTop: 10,
      fontSize: 15,
      color: '#a3a3a3',
      padding: 0,
      fontFamily: EFontFamily.poppins,
      marginBottom: 0
    },
    fileName: {
      padding: 0,
      margin: 0,
      color: '#6f6f6f',
      fontFamily: EFontFamily.poppins,
      fontSize: 14,
      fontWeight: 500
    },
    fileSize: {
      padding: 0,
      margin: 0,
      color: '#a3a3a3',
      fontFamily: EFontFamily.poppins,
      fontSize: 13
    },

    modalTitle: {
      fontFamily: EFontFamily.poppins,
      color: theme.palette.grey[800],
      marginTop: 0
    },
    ArrowRightIcon: {
      position: 'relative',
      top: 3,
      left: 3
    },
    fileList: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 16
    },
    alert: {
      position: 'absolute',
      top: 84,
      right: 22,
      width: rem(384),
      backgroundColor: '#6C8979;',
      borderRadius: 10
    },
    thumb: {
      display: 'inline-flex',
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: '100%',
      height: 80,
      padding: 4,
      borderRadius: 13,
      backgroundColor: '#f1f3f4'
    },
    thumbInner: {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden',
      width: '100%'
    },
    thumbsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16
    },
    pdfThumbnailContainer: {
      width: '60px',
      height: '60px',
      overflow: 'hidden',
      border: '1px solid rgb(217 217 217)',
      borderRadius: '8px',
      marginRight: 20,
      marginLeft: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    pdfThumbnailImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    pdfContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    pdfContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    iconDelete: {
      color: '#878787',
      backgroundColor: '#f1f3f4'
    },
    attachmentPreviewContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    attachmentPreviewContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > img': {
        display: 'block',
        width: '60px',
        height: '60px',
        objectFit: 'cover',
        borderRadius: '8px',
        margin: '4px',
        marginRight: 20,
        marginLeft: 15,
        border: '1px solid rgb(217 217 217)'
      }
    },
    loadingFeedback: {
      position: 'relative',
      top: '8%',
      right: '443px',
      height: '3.6875rem',
      width: '4.25rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '7px',
      zIndex: 1,
      backgroundColor: '#ffffffd1'
    },
    modalButton: {
      borderRadius: 8,
      fontSize: 14
    },
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2
    }
  })();
};

export default useStyle;
