import React, { useState } from 'react';
import { IconButton, Snackbar, Grid } from '@mui/material';
import StaticField from 'components/Shared/StaticField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useStyle from './style';

function DetailRow({
  label1,
  value1,
  label2,
  value2,
  isOpen
}: {
  label1: string;
  value1: string | null;
  label2?: string;
  value2?: string;
  isOpen: boolean;
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopy = (textToCopy: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const classes = useStyle();

  return (
    <>
      <div>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={6} container alignItems='center'>
            <div className={classes.fieldContainer}>
              <StaticField label={label1} value={value1 || '-'} />
              {isOpen && (
                <IconButton
                  aria-label='copy'
                  size='small'
                  onClick={() => handleCopy(`${label1}: ${value1 || '-'}`)}
                  className={classes.copyButtonLeft}
                >
                  <ContentCopyIcon fontSize='small' />
                </IconButton>
              )}
            </div>
          </Grid>
          {label2 && value2 && (
            <Grid item xs={6} container alignItems='center'>
              <div className={classes.fieldContainer}>
                <StaticField label={label2} value={value2 || '-'} />
                {isOpen && (
                  <IconButton
                    aria-label='copy'
                    size='small'
                    onClick={() => handleCopy(`${label2}: ${value2 || '-'}`)}
                    className={classes.copyButtonRight}
                  >
                    <ContentCopyIcon fontSize='small' />
                  </IconButton>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message='Item copiado!'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
}

export default DetailRow;
