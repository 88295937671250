import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@mui/material/styles';
import { infraction } from 'services/antt';
import { Attach, getAttachments, uploadAttach } from 'services/antt/attachments';
import { AttachFileIcon, AttachFileIconHandle } from 'assets/images/icon-animated';
import Divider from 'components/Shared/Divider';
import { Modal, Box, Button, IconButton, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { bytesToMB } from 'helpers/formats';
import { ArrowRightIcon, ArrowRightIconHandle } from 'assets/images/icon-animated/arrow-right';

import useStyles from './styles';
import FileItem from './FileItem';
import CircularProgressWithLabel from './CircularProgress';
import PdfThumbnail from './PdfThumbnail';

function Attachments(props: { infractionData: infraction }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [files, setFiles] = useState<Array<{ file: File; preview: string; progress: number }>>([]);
  const [openModal, setOpenModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const { infractionData } = props;

  const attachFileIconRef = useRef<AttachFileIconHandle>(null);
  const arrowRightIconRef = useRef<ArrowRightIconHandle>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [attachments, setAttachments] = useState<Attach[]>([]);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [showSuccessAlert]);

  const onAttachmentsFetched = (attachments: Attach[]) => {
    setAttachments(attachments);
    setIsLoading(false);
  };

  const uploadFile = async () => {
    setIsLoading(true);

    try {
      const uploadPromises = files.map(({ file }, index) => {
        const formData = new FormData();
        formData.append('file', file);

        const simulateProgress = () => {
          setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            if (newFiles[index].progress < 100) {
              newFiles[index].progress += 10;
              setTimeout(simulateProgress, 500);
            }
            return newFiles;
          });
        };

        simulateProgress();

        return uploadAttach(infractionData.id, formData)
          .then(() => {
            setFiles(prevFiles => {
              const newFiles = [...prevFiles];
              newFiles[index].progress = 100;
              return newFiles;
            });
          })
          .catch(() => {
            setFiles(prevFiles => {
              const newFiles = [...prevFiles];
              newFiles[index].progress = 0;
              return newFiles;
            });
          });
      });

      await Promise.all(uploadPromises);
      setShowSuccessAlert(true);
      getAttachments(onAttachmentsFetched, infractionData.id);
    } catch (error) {
      console.error('Erro ao enviar arquivos:', error);
      setIsLoading(false);
    } finally {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    getAttachments(onAttachmentsFetched, infractionData.id);
  }, [infractionData.id]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': []
    },
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file => ({
          file,
          preview: URL.createObjectURL(file),
          progress: 0
        }))
      );
      setOpenModal(true);
    }
  });

  const handleRemoveFile = (fileName: string) => {
    setFiles(prevFiles => prevFiles.filter(file => file.file.name !== fileName));
  };

  useEffect(() => {
    if (files.length === 0) {
      setOpenModal(false);
    }
  }, [files]);

  const thumbs = files.map(({ file, preview, progress }) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        {file.type === 'application/pdf' ? (
          <div className={classes.pdfContainer}>
            <div className={classes.pdfContent}>
              <PdfThumbnail file={file} preview={preview} onLoad={() => URL.revokeObjectURL(preview)} />
              <div>
                <p className={classes.fileName}>{file.name}</p>
                <p className={classes.fileSize}>{bytesToMB(file.size)} MB</p>
              </div>
            </div>
            <div>
              <IconButton
                aria-label='delete'
                onClick={() => handleRemoveFile(file.name)}
                disabled={isLoading}
                className={classes.iconDelete}
                sx={{ position: 'relative', left: isLoading ? 22 : -38 }}
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className={classes.attachmentPreviewContainer}>
            <div className={classes.attachmentPreviewContent}>
              <img
                src={preview}
                alt={`Preview of ${file.name}`}
                onLoad={() => {
                  URL.revokeObjectURL(preview);
                }}
              />
              <div>
                <p className={classes.fileName}>{file.name}</p>
                <p className={classes.fileSize}>{bytesToMB(file.size)} MB</p>
              </div>
            </div>
            <div>
              <IconButton
                aria-label='delete'
                onClick={() => handleRemoveFile(file.name)}
                disabled={isLoading}
                className={classes.iconDelete}
                sx={{ position: 'relative', left: isLoading ? 22 : -38 }}
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            </div>
          </div>
        )}
        {isLoading && (
          <Box className={classes.loadingFeedback}>
            <CircularProgressWithLabel value={progress} />
          </Box>
        )}
      </div>
    </div>
  ));

  useEffect(() => {
    return () => files.forEach(({ preview }) => URL.revokeObjectURL(preview));
  }, [files]);

  const handleMouseEnter = () => {
    attachFileIconRef.current?.startAnimation();
    arrowRightIconRef.current?.startAnimation();
  };

  const handleMouseLeave = () => {
    attachFileIconRef.current?.stopAnimation();
    arrowRightIconRef.current?.stopAnimation();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <section className={classes.container}>
        <div
          {...getRootProps({ className: 'dropzone' })}
          className={classes.dropzone}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input {...getInputProps()} />
          <AttachFileIcon ref={attachFileIconRef} className={classes.dropzoneIcon} />
          <p className={classes.dropzoneText}>Clique para enviar arquivos ou arraste e solte</p>
        </div>
      </section>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}
        >
          <Divider title='Arquivos Selecionados' className={classes.modalTitle} />
          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            <div className={classes.thumbsContainer}>{thumbs}</div>
          </Box>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleCloseModal}
              sx={{ mr: 2 }}
              variant='outlined'
              size='large'
              className={classes.modalButton}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              onClick={uploadFile}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              disableElevation
              size='large'
              disabled={isLoading}
              className={classes.modalButton}
            >
              Adicionar
              <ArrowRightIcon ref={arrowRightIconRef} className={classes.ArrowRightIcon} />
            </Button>
          </Box>
        </Box>
      </Modal>

      {showSuccessAlert && (
        <Alert variant='filled' severity='success' className={classes.alert}>
          Anexo adicionado com sucesso
        </Alert>
      )}

      <div className={classes.fileList}>
        {attachments.map(attach => (
          <FileItem key={attach.id} file={attach} infraction={infractionData} />
        ))}
      </div>
    </div>
  );
}

export default Attachments;
