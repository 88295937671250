import { createUseStyles } from 'react-jss';
import { EFontFamily } from 'helpers/constants';
import Button from '@mui/material/Button';
import { Card, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import CarPattern from 'assets/images/car_pattern.jpg';
import GeometricShapes from 'assets/images/geometric_shapes.png';
import Tabs from '@mui/material/Tabs';
import { rem } from 'helpers/utils';
import { common } from '@mui/material/colors';

const cardCustomStyles = {
  background: common.white,
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  border: '1px solid #cbcbcb'
};

export const CardCustom = styled(Card)(() => ({
  ...cardCustomStyles,
  borderRadius: rem(15)
}));

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderRadius: rem(10),
  marginBottom: rem(10),

  '& .MuiTabs-scroller': {
    backgroundColor: common.white,
    borderRadius: rem(10),
    padding: `${rem(8)}`,

    '& .Mui-selected': {
      color: common.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: rem(10),
      padding: `0 ${rem(40)} 0 ${rem(40)}`,
      transition: 'all 0.3s ease-in-out'
    },

    '& .MuiTabs-indicator': {
      display: 'none'
    },

    '& .MuiButtonBase-root ': {
      minHeight: 0,
      fontSize: rem(14),
      fontWeight: 400,
      textTransform: 'initial'
    }
  },

  ...cardCustomStyles
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: rem(8),
  paddingLeft: rem(30),
  paddingRight: rem(30),
  backgroundColor: theme.palette.primary.main,
  color: common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  }
}));

const useStyle = (theme: Theme) =>
  createUseStyles({
    topInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: theme.palette.primary.main,
      padding: `${rem(18)} ${rem(10)} ${rem(10)} ${rem(50)}`,
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: rem(13),
        right: 0,
        bottom: 0,
        left: rem(26),
        backgroundImage: `url(${GeometricShapes})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: rem(90),
        zIndex: 1,
        height: rem(106),
        width: rem(90)
      }
    },
    container: {
      borderLeft: '9px solid #888EA2'
    },
    cardContent: {
      padding: `${rem(10)} ${rem(16)} ${rem(16)} ${rem(30)}`
    },
    tabsContainer: {
      padding: `${rem(10)} 0`
    },
    bottomButtons: {
      display: 'flex',
      color: theme.palette.secondary.light,
      justifyContent: 'space-between',
      paddingTop: rem(30),
      '& a': {
        textDecoration: 'none'
      }
    },
    badgeAttachments: {
      '& .MuiBadge-badge': {
        backgroundColor: '#fff',
        color: '#000',
        position: 'relative',
        height: rem(18),
        right: rem(3),
        top: rem(10)
      }
    },
    badge: {
      '& .MuiBadge-badge': {
        backgroundColor: '#807aa3',
        color: '#fff',
        fontSize: rem(12),
        position: 'relative',
        height: rem(20),
        right: rem(3),
        top: rem(10)
      }
    },
    ascriptions: {
      marginLeft: rem(20),

      '& .MuiCardContent-root': {
        padding: `${rem(18)} ${rem(24)}`,
        borderRadius: rem(94)
      }
    },
    imageBg: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      zIndex: 0,
      marginLeft: 20,

      '& .MuiCardContent-root': {
        padding: '18px 24px',
        borderRadius: 94
      }
    },
    transportDocument: {
      marginLeft: rem(20),
      background: '#759884',
      marginBottom: rem(10),
      color: common.white,
      fontWeight: 300,
      fontSize: rem(14),
      fontFamily: EFontFamily.inter,
      height: rem(200),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',

      '& img': {
        width: 70,
        height: 70,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },

      '& p': {
        padding: '0 50px',
        marginBottom: 0
      },

      '& .MuiCardContent-root': {
        padding: `${rem(18)} ${rem(24)}`,
        borderRadius: rem(94)
      },

      '& .MuiBadge-badge': {
        position: 'relative',
        right: rem(40),
        top: rem(8),
        backgroundColor: '#575757'
      },

      '& .MuiButtonBase-root': {
        backgroundColor: '#93aa9d',
        width: '100%',
        height: rem(46),
        color: common.white,
        position: 'relative',
        top: rem(13),
        fontWeight: 500,
        fontFamily: EFontFamily.inter,

        '&:hover': {
          backgroundColor: '#93aa9da3'
        }
      }
    }
  });

export const TopItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  position: 'relative',
  zIndex: 2,

  '& div': {
    backgroundColor: '#5E597D',
    padding: `${rem(6)} ${rem(16)}`,
    borderRadius: rem(8),
    margin: `${rem(28)} ${rem(10)} 0 0`
  },

  '& p': {
    margin: 0,
    color: common.white,
    fontSize: rem(13),
    fontWeight: 100,

    '& span': {
      fontWeight: 400,
      fontSize: rem(13),
      fontFamily: EFontFamily.inter
    }
  }
}));

export const TopTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: common.white,

  '& h2': {
    margin: 0,
    fontSize: rem(24),
    fontWeight: 600,
    fontFamily: EFontFamily.inter,
    position: 'relative',
    zIndex: 2
  },
  '& p': {
    margin: 0,
    fontSize: rem(16),
    fontWeight: 200,
    fontFamily: EFontFamily.inter,
    position: 'relative',
    zIndex: 2
  },

  '& .MuiButtonBase-root': {
    backgroundColor: common.white,
    color: '#6B6464',
    textTransform: 'unset',
    fontSize: rem(13),
    padding: `${rem(9)} 0`,
    fontFamily: EFontFamily.inter,
    margin: `${rem(36)} ${rem(76)} 0 0`,
    borderRadius: rem(8),
    cursor: 'pointer',
    width: rem(210),
    height: rem(38),

    '&:hover': {
      backgroundColor: '#e7e6ef'
    }
  },

  '& .MuiLoadingButton-root': {
    backgroundColor: common.white
  },

  '& .MuiLoadingButton-loading': {
    backgroundColor: '#E9E9E9 !important'
  }
}));

export const CardHeader = styled('div')(() => ({
  position: 'relative',
  backgroundColor: '#7F79A3',
  borderRadius: `${rem(20)} ${rem(42)} ${rem(20)} ${rem(20)}`,
  height: rem(168),
  marginBottom: rem(20),
  overflow: 'hidden',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: `url(${CarPattern})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.04,
    filter: 'grayscale(100%)'
  }
}));

export default useStyle;
