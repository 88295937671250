import { Box, IconButton, styled } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export const CustomList = styled(List)(({ theme }) => ({
  paddingTop: 18,
  backgroundColor: theme.palette.primary.main,
  margin: 6
}));

export const CustomListItem = styled(ListItem)(() => ({
  position: 'relative',
  color: '#f2f2f2',
  padding: '12px 12px',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    width: '100%',
    height: '100%',
    maxWidth: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'all .3s ease-in-out',
    backgroundColor: '#605a82',
    borderRadius: 6,
    zIndex: 3
  },
  '&:hover::before': { maxWidth: '100%' }
}));

export const ListIconStyle = {
  minWidth: 'unset'
};

export const IconStyle = {
  width: 22,
  height: 22,
  color: '#fff',
  marginRight: 12,
  opcity: 0.3
};

export const CustomListItemText = styled(ListItemText)(() => ({
  margin: 0,
  '& > span': {
    fontSize: 14,
    fontWeight: 300,
    position: 'relative',
    zIndex: 100,
    fontFamily: 'Poppins, sans-serif'
  }
}));

export const CustomBox = styled(Box)(() => ({
  '& ul.MuiList-root > :nth-of-type(2)': {
    bottom: 38
  }
}));

export const TextDecorationNone = {
  textDecoration: 'none'
};

export const CustomIconButton = styled(IconButton)(() => ({
  position: 'fixed',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1300,
  backgroundColor: '#fff',
  border: '2px solid #696391',
  transition: 'left 0.3s',
  color: '#696391',
  height: 36,
  width: 36,

  '&:hover': {
    backgroundColor: '#fff',
    zIndex: 1300
  }
}));
