import api from './api';

export function getSapTransportData(InfractionId: string | number, callback: (data: any) => void) {
  api
    .get(`/sap/transports/infraction/${InfractionId}`)
    .then(result => {
      const { data } = result;

      callback(data);
    })
    .catch((error: any) => {
      console.log('Error fetching SAP transport data: ', error);
    });
}
