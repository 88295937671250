import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '10px 2px'
  },

  copyButtonLeft: {
    position: 'absolute',
    backgroundColor: '#fff',
    left: '44%'
  },

  copyButtonRight: {
    position: 'absolute',
    backgroundColor: '#fff',
    right: '4%'
  }
});

export default useStyle;
