import { tableRequest } from 'services/antt';

export const formatCurrency = (value: number, showSign?: boolean) => {
  const optSign = { style: 'currency', currency: 'BRL' };
  const optNoSign = { minimumFractionDigits: 2 };

  return new Intl.NumberFormat('pt-BR', showSign ? optSign : optNoSign).format(value);
};

export const formatPercentage = (value: number) => {
  return new Intl.NumberFormat('pt-BR', { style: 'percent' }).format(value / 100);
};

export const bytesToMB = (bytes: number): string => {
  const MB = bytes / (1024 * 1024);
  return MB.toFixed(2);
};

export const buildInfractionUrl = (params: tableRequest) => {
  const { dateRef = '', from = '', to = '', filters = '' } = params;
  const hasDateRef = dateRef ? `&date_ref=${dateRef}` : '';
  const hasFrom = from ? `&from=${from}` : '';
  const hasTo = to ? `&to=${to}` : '';

  const url = `&${hasDateRef}${hasFrom}${hasTo}${filters}`;

  return url;
};
