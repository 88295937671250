import { rem } from 'helpers/utils';
import { styled } from '@mui/material/styles';
import { TableCell, tableCellClasses } from '@mui/material';
import { theme } from 'components/Layout/ThemeProvider';
import { createUseStyles } from 'react-jss';

export const Row = styled('div')(() => ({
  padding: `${rem(15)} 0`
}));

export const Dialog = styled('dialog')(() => ({
  border: '1px solid #cbcbcb',
  padding: 0,
  margin: 0,
  borderRadius: rem(13),
  height: 'auto',
  width: '36vw',
  overflow: 'hidden',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.25)',
  zIndex: 9999,

  '& .MuiDialogTitle-root': {
    backgroundColor: '#696391',
    padding: '11px 26px',
    fontSize: rem(17),
    fontFamily: 'Poppins',
    color: '#fff',
    cursor: 'move',
    position: 'sticky',
    top: 0,
    zIndex: 10
  },

  '& .MuiDialogContent-root': {
    padding: `${rem(18)} ${rem(25)}`
  },

  '& .MuiTypography-root': {
    '& .MuiButtonBase-root:nth-of-type(1)': {
      '& svg': {
        fontSize: rem(18)
      }
    }
  },

  '& .MuiTableHead-root': {
    backgroundColor: ' #fff',
    position: 'relative',
    top: 0
  },

  '& .highlighted-row': {
    backgroundColor: '#f0f0f0'
  },

  '& svg': {
    color: '#fff'
  },

  '& .MuiTableCell-root .MuiSvgIcon-root': {
    color: 'rgb(181 181 181)'
  },

  '& .MuiTableBody-root': {
    border: 'none'
  },

  '& .MuiTableCell-root': {
    border: 'none'
  },

  '&::-webkit-scrollbar': {
    width: rem(10)
  },

  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#888'
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555'
  }
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginBottom: rem(10)
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  },
  [`&.${tableCellClasses.root}`]: {
    padding: 0
  },
  [`&.${tableCellClasses.body}`]: {
    padding: 0,
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }
}));

export const useStyle = createUseStyles({
  datatableWrapper: {
    position: 'relative',
    '& [role="toolbar"]': {
      flexDirection: 'row-reverse',
      '& > div:first-child': {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& > div': {
          border: '1px solid #cacaca',
          padding: '0 8px'
        },
        '& div::before': {
          display: 'none'
        },
        '& div::after': {
          display: 'none'
        }
      },
      '& button': {
        display: 'none'
      }
    },
    '& table': {
      fontSize: 14,
      lineHeight: '100%',
      border: 'none',
      borderBottom: 'none',
      '& thead': {
        '& th': {
          color: '#fff',
          textTransform: 'uppercase',
          fontWeight: 500,
          backgroundColor: '#898989',
          padding: '12px 16px',
          '&:first-child': {
            borderRadius: '18px 0px 0px 18px'
          },
          '&:last-child': {
            borderRadius: '0px 18px 18px 0px'
          },
          '& > div > span': {
            color: '#fff',
            padding: 0,
            '&[class*=checked], &[class*=indeterminate]': {
              color: '#fff'
            }
          }
        },
        '& button': {
          fontSize: 'inherit',
          padding: 'inherit',
          minWidth: 'unset',
          '& div, & svg': {
            color: '#fff !important'
          }
        }
      },
      '& tbody': {
        borderLeft: '#fdf5f5 thin solid',
        borderRight: '#fdf5f5 thin solid',
        '& tr': {
          borderBottom: '#f1f1f1 thin solid',
          '&:last-child': {
            borderBottom: 'none'
          },
          '&.mui-row-selected': {
            backgroundColor: 'rgb(237 233 249)',
            border: '1px solid #9e99b7'
          }
        },
        '& td': {
          padding: 8,
          color: '#54585a',
          position: 'relative',
          '&:first-child': {
            textAlign: 'center',
            paddingLeft: 16
          },
          '& > div > span': {
            padding: 0,
            color: theme.palette.secondary.light,
            '&[class*=checked], &[class*=indeterminate]': {
              color: '#9e99b7'
            }
          }
        }
      },
      '&.check-row': {
        '& tr': {
          cursor: 'pointer',
          '&.selected': {
            '& td': {
              backgroundColor: theme.palette.success.light,
              color: '#fff'
            }
          }
        }
      },
      '& .sign': {
        width: 10,
        height: 10,
        display: 'block',
        margin: 'auto',
        '&.active': { backgroundColor: theme.palette.success.main },
        '&.inactive': { backgroundColor: '#ccc' }
      }
    },
    '& > div > div': {
      paddingLeft: 0,
      paddingRight: 0
    },
    '& [class*="emptyTitle"]': {
      fontSize: 13,
      padding: '24px 0'
    },
    '& [class*="navContainer"] p': {
      fontSize: 12
    },
    '& [aria-label="Search"]': {
      fontSize: 13,
      minHeight: 25
    }
  },
  dialog: {
    position: 'absolute',
    top: '35%',
    left: '35%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'auto'
  },
  tableContainer: {
    overflow: 'auto',
    borderRadius: '20px',
    border: 'none'
  }
});

export default useStyle;
